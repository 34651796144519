<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <v-alert
          class="mx-2 white--text"
          border="left"
          colored-border
          :color="$store.state.secondaryColor"
        >
          <v-form ref="form">
            <v-layout column>
              <span class="black--text"> {{ $t("package name") }} : </span>

              <input
                type="text"
                class="input-style caption my-2"
                readonly
                v-model="selectedPackage"
              />

              <span class="black--text"> {{ $t("owner's name") }} : </span>

              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <input
                    type="text"
                    class="input-style caption my-2"
                    v-on="on"
                    v-model="searchOwner"
                  />
                </template>
                <v-list dense class="pa-0">
                  <div v-for="owner in owners" :key="owner.id">
                    <v-list-item @click="selectOwner(owner)">{{
                      getFullnames(owner.names)
                    }}</v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-list>
              </v-menu>

              <v-btn
                :color="$store.state.primaryColor"
                :loading="loading"
                dark
                large
                class="my-2"
                @click="savePackage()"
                >{{ $t("select") }}</v-btn
              >
            </v-layout>
          </v-form>
        </v-alert>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackWarning"
      top
      color="red lighten-5 error--text text--darken-3"
    >
      <span> {{ $t("there are empty fields") }}</span>

      <template v-slot:action="{ attr }">
        <v-btn
          icon
          color="error darken-3"
          v-bind="attr"
          @click="snackWarning = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackExists"
      top
      color="warning lighten-5 warning--text text--darken-3"
    >
      <span>{{ $t("owner already registered with license") }}</span>

      <template v-slot:action="{ attr }">
        <v-btn
          icon
          color="warning darken-3"
          v-bind="attr"
          @click="snackExists = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackSuccess"
      bottom
      color="success lighten-5 success--text text--darken-3"
    >
      <span>{{ $t("license registered") }}</span>
      <template v-slot:action="{ attr }">
        <v-btn
          v-bind="attr"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import { format } from "date-fns";
export default {
  data: () => ({
    logs: [],
    loading: false,
    searchOwner: "",
    licenses: [],
    ownerData: [],
    packageData: "",
    selectedPackage: "",
    register: {
      packageId: "",
      ownerId: "",
      startDate: "",
      expiryDate: "",
      isValid: true,
    },

    snackExists: false,
    snackSuccess: false,
    snackloading: false,
    snackWarning: false,
  }),

  computed: {
    owners() {
      return this.ownerData.filter((val) => {
        return (
          val.names.first
            .toLowerCase()
            .includes(this.searchOwner.toLowerCase()) ||
          val.names.middle
            .toLowerCase()
            .includes(this.searchOwner.toLowerCase()) ||
          val.names.last.toLowerCase().includes(this.searchOwner.toLowerCase())
        );
      });
    },
  },

  created() {
    this.getPackage();
    this.getLicenses();
  },

  methods: {
    getPackage() {
      db.collection("packages")
        .doc(this.$route.params.packageId)
        .get()
        .then((snaps) => {
          this.packageData = snaps.data();
          this.selectedPackage = snaps.data().name;
          this.register.packageId = this.$route.params.packageId;
        });
    },

    getLicenses() {
      (this.ownerData = []),
        db
          .collection("licenses")
          .get()
          .then((snapshot) => {
            if (snapshot.size > 0) {
              snapshot.forEach((snaps) => {
                this.getOwners(snaps.data().ownerId);
              });
            } else {
              this.getOwners("");
            }
          });
    },

    getOwners(ownerId) {
      db.collection("owners")
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            if (!this.ownerData.some((v) => v.id == snaps.id)) {
              if (snaps.id != ownerId) {
                this.ownerData.push({
                  id: snaps.id,
                  ...snaps.data(),
                });
              }
            }
          });
        });
    },

    getFullnames(names) {
      return names.first + " " + names.middle.substr(0, 1) + ". " + names.last;
    },

    selectOwner(data) {
      this.searchOwner = this.getFullnames(data.names);
      this.register.ownerId = data.id;
    },

    DateFormat(data) {
      return data != null ? format(data.toDate(), "eee dd-MMM-yyyy hh:mm") : "";
    },

    savePackage() {
      if (this.register.packageId == "" || this.register.ownerId == "") {
        this.snackWarning = true;
      } else {
        this.loading = true;
        let day = new Date().getDate();
        let month = new Date().getMonth() + 1;
        let nextYear = new Date().getFullYear() + 1;

        let newDate = nextYear + "-" + month + "-" + day;
        this.register.startDate = new Date();
        this.register.expiryDate = new Date(newDate);

        db.collection("licenses")
          .add(this.register)
          .then(() => {
            this.snackSuccess = true;
            this.loading = false;
            this.$router.push({ name: "Packages" });
          });
      }
    },
  },
};
</script>

<style></style>
